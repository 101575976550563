/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AdhocPaymentResult,
  CartHistoryQueryResponse,
  CartHistoryQueryRow,
  PreemptionResponse,
  StickerPurchasePeriodList,
  StickerPurchasePeriodResponse,
  StickerType,
  StickerTypeListResponse,
} from "@/core/dataTypes/types";
import { EStickerTypeValue } from "@/core/dataTypes/enum";
import { formatDate } from "@/core/filters";

export const transformStickerTypeList = (
  data: StickerTypeListResponse
): StickerType[] => {
  return data.stickerTypeList.stickerType.map((item: StickerType) => {
    const stickerType = EStickerTypeValue as any;
    return {
      id: item.id,
      name: stickerType[item.id],
      loading: false,
    };
  });
};

export const transformStickerPurchasePeriod = (
  data: StickerPurchasePeriodResponse
): StickerPurchasePeriodList[] =>
  [...Array(data.stickerPurchasePeriod + 1).keys()].slice(1).map((item) => {
    return {
      value: item.toString(),
    };
  });

export const transformGetPreemption = (
  data: PreemptionResponse
): PreemptionResponse => {
  return {
    preemption: data.preemption,
    validityYearList: {
      validityYear: data.validityYearList.validityYear.map((item: any) => {
        return {
          value: item,
        };
      }),
    },
  };
};

export const transformTimeZone = (date: string | number | Date) => {
  return formatDate(date as any, "yyyy-MM-ddTHH:mm:ss");
  // return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss");;
};

export const transformDate = (
  date: string | Date,
  formatPattern: string
): string => {
  return formatDate(date as any, formatPattern);
  // return format(new Date(date), formatPattern);
};

export const transformInvoiceData = (
  data: CartHistoryQueryResponse
): CartHistoryQueryRow[] => {
  return data.rowList.cartHistoryQueryRow.map((item) => {
    item.loading = false;
    item.cartElementList.cartElement = item.cartElementList.cartElement.map(
      (cart) => {
        cart.loading = false;
        return cart;
      }
    );
    return item;
  });
};

export const transformAdhocHistoryData = (data: AdhocPaymentResult): any => {
  data.cart.cartElementList.cartElement.map((item: any) => {
    item.loading = false;
    return item;
  });
  return data;
};
