import { AddressType } from "@/core/dataTypes/types";
import { i18n } from "@/locales/i18n";
import moment from "moment-timezone";

export const formatDate = (isoDate: string | number, param: string): string => {
  const timeZone = "Europe/Budapest";
  const format = param.replace(/dd/g, "DD"); // YYYY.MM.DD HH:mm:ss
  return moment.tz(isoDate, timeZone).format(format);
};

export const toFullAddress = (address: Partial<AddressType>): string => {
  let addressText = "";
  addressText = addressText.concat(
    address.zipCode ? address.zipCode.toString() + " " : "",
    address.city ? address.city + ", " : "",
    address.street ? address.street + " " : "",
    address.streetType ? address.streetType + " " : "",
    address.streetNumber ? address.streetNumber + " " : "",
    address.floor ? address.floor + " " : "",
    address.door ? address.door.toString() : ""
  );
  return addressText;
};
export const formatCurrency = (number: number): string =>
  new Intl.NumberFormat("hu-HU").format(number);

export const toLanguageText = (language: string): string => {
  const languages = [
    { value: "HU", title: i18n.tc("PROFILE_DETAIL_PAGE.LANG_HU") },
    { value: "EN", title: i18n.tc("PROFILE_DETAIL_PAGE.LANG_EN") },
    { value: "DE", title: i18n.tc("PROFILE_DETAIL_PAGE.LANG_DE") },
  ];
  return languages.find((lang) => lang.value === language)?.title as string;
};
